<template>
	<ConfirmDialog></ConfirmDialog>
	
	<div class="p-grid" style="height: 100%">
		<div class="p-col-12 p-md-12" style="height: 100%">
			<div class="card p-fluid" style="height: 100%">
                <CrmDataTable baseEntityName="bm_otomasyonprojesi" :options="CrmDataTable_options" :isHeaderVisible="true" :doubleClickOpenEntity="true" :isNewButtonVisible="true" />
            </div>
        </div>
    </div>
</template>

<script>
import user from '../store/user';

export default {
	data() {
		return {
			CrmDataTable_options: {
				searchAttributes: ["bm_name", "bm_projekodu", "bm_firmaname"]
            },
		}
	},
	created() {
		if (this.profileData) {
			try {
				if (this.profileData.moduller) {
					const yetkisiVarmi = user.checkPermissionModul(this.profileData, 'Otomasyon Projesi');
					if (yetkisiVarmi == false) {
						console.log('this.profileData içinde yetki bulunamadı!');
						console.log(this.profileData);
						this.$router.replace({ name: 'accessdenied' });
					}
				}
			} catch (error) {
				console.log('yetkisiVarmi HATA: ' + error);
			}
		}
		else {
			console.log(this.profileData);
			console.log('this.profileData BOŞ GELDİ');
		}
	},
	computed: {
		profileData(){
			return this.$store.getters.getProfile;
		}
	}
}
</script>

<style lang="scss" scoped>

</style>
